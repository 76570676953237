import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import routes from './routes';

import Top from './pages/Top';
import Contact from './pages/Contact';
import About from './pages/About';
import Schedule from './pages/Schedule';
import News from './pages/News';
import Staffs from './pages/Staffs';
import Recruit from './pages/Recruit';
import Gallery from './pages/Gallery';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import List from './pages/List';
import GoogleCalendar from './pages/develop/GoogleCalendar';

/**
 * top page
 * contact
 * about
 * @returns 
 */
function App() {
  return (
    <Router>
      <Routes>
        <Route path={routes.top} element={<Top />} />
        <Route path={routes.contact} element={<Contact />} />
        <Route path={routes.about} element={<About />} />
        <Route path={routes.schedule} element={<Schedule />} />
        <Route path={routes.news} element={<News />} />
        <Route path={routes.staffs} element={<Staffs />} />
        <Route path={routes.recruit} element={<Recruit />} />
        <Route path={routes.gallery} element={<Gallery />} />
        <Route path={routes.privacy_policy} element={<PrivacyPolicy />} />
        <Route path={routes.top} element={<Top />} />
        <Route path={routes.terms_of_use} element={<TermsOfUse />} />
         {/* development */}
        <Route path={routes.list} element={<List />} />
        <Route path={routes.googlecalendar} element={<GoogleCalendar />} />
      </Routes>
    </Router>
  );
}
export default App;
