import React, { useEffect }from "react";
import { useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/main.scss';

import Nav from '../components/Nav';
import Footer from '../components/Footer';

const About = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const offset = 80; // ここでオフセットを調整（50px上にスクロール）
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: elementPosition - offset, behavior: "smooth" });
      }
    }
  }, [hash]);

	return (
		<>
      <Nav />
        <div className="container about">
          <div className="row pt100 pb100">
            <h1 className="mb30">密蜜について</h1>
            <div className="col-sm-12 col-md-6 col-lg-6 mb20">
              <p className="mb50">
                歌舞伎町に佇む和風SMバー「密蜜 - mitsu -」。<br />
                初心者から上級者まで幅広い方にお楽しみいただけるよう、落ち着いた雰囲気の中で心地よい刺激と交流を提供しております。<br />
                楽しみ方は人それぞれ<br />
                Fetishな会話に花を咲かせるもよし、SMを楽しむもよし、BAR体験としてただそこに居るのもよしです。<br />
                初心者の方も安心してお越しください。<br />
                <br />
                新しい自分を発見できる場所として、非日常的な体験を楽む場所とて、あなたをお待ちしております。<br />
              </p>

              <div className="about-item mt50" id="mitsu_system">
                <h3 className="about-item-title">
                  <img src="/assets/common/h1_flower2.png" alt="arrow" className="flower"/>システム
                </h3>
                <div className="about-item-text">
                  <strong>通常営業時間</strong>
                  <hr />
                  夜の営業：月〜日 18:00～24:00<br />
                  昼の営業：火水土日 13:00～18:00<br />
                  <br />
                  <strong>料金</strong>
                  <hr />
                  フリータイム・フリードリンク<br />
                  男性: 7,000円<br />
                  *ボトルキープのお客様は次回来店時よりチャージ 5,000円<br />
                  女性: 3,000円<br />
                  <br />
                  昼営業から継続して夜営業を利用のお客様は、1000円割引となります。<br />
                  尚、お支払いは現金のみとさせていただきます。
                </div>

                <div className="about-item-text mt20">
                <strong>密蜜 ↔ 初心（ubu）との往来サービス</strong><br />
                <hr />
                  密蜜から初心、初心から密蜜へ同日にお越しのお客様は<br />
                  2店舗目のチャージを下記のように割引させていただきます。<br />
                  （夜営業のみとさせていただきます）
                  <br /><br />
                    男性: 3,000円（通常チャージより4,000円割引）<br />
                    女性: 1,000円（通常チャージより2,000円割引）<br />
                  <br />
                </div>

                <div className="about-item-text mt20">
                  <strong>SM体験サービス</strong><br />
                  <hr />
                  縛りなどのsm体験をご希望のお客様は、スタッフまでお申し出ください。<br />
                  <br />
                  ・体験料は  <br />
                  男性 2,000円 <br />
                  女性 1,000円 <br />
                  となります。<br />
                  <br />
                  密蜜では、お客様同士のプレイも可能です。<br />
                  ・双方の合意が必須となります。<br />
                  ・体験料はかかりません。(無料)<br />
                </div>

                {/* <div className="about-item-text mt20">
                  <strong>密蜜札</strong><br />
                  <hr />
                  SMしたい、を言葉にしたくないお客様のために、密蜜札をご用意しております。<br />
                  <br />
                  密蜜札は縛られたい、SMしたいと希望されたお客様が、指定した方（スタッフやお客様）に差し出すことで、同意のもと希望を叶える札になります。<br />
                  <br />
                  ・スタッフへの依頼<br />
                    1000円<br />
                  ・お客様同士は適用外<br />
                  (お客様同士での密蜜札の利用には料金は掛かりません)<br />
                </div> */}

                <div className="about-item-text mt30">
                  <strong>密蜜スタンプカード</strong><br />
                  <hr />
                  ご来店のお客様に、スタンプカードを発行させていただいております。<br />
                  以下の各条件で1スタンプを取得できます。
                  <br /><br />
                  【密蜜スタンプの条件】<br />
                  <ul>
                    <li>通常営業の来店時</li>
                    <li>密蜜での密蜜札の使用</li>
                    <li>密蜜札の購入</li>
                    <li>RopeMAGIC写真集の購入</li>
                    {/* <li>講習会へのモデル参加</li> */}
                    {/* <li>密蜜弁財天へのモデル参加</li> */}
                    {/* <li>Googleでの評価クチコミ記載</li> */}
                    {/* <li>密蜜カレンダーのRT</li> */}
                    <li>密蜜弁財天への来店</li>
                    <li>LAZYCafeへの来店</li>
                  </ul>
                  スタンプが5つ貯まると<br />
                  1000円分として、チャージや密蜜札使用に利用出来ます。<br />
                </div>
              </div>

              <div className="about-item" id="mitsu_menu">
                <h3 className="about-item-title">
                  <img src="/assets/common/h1_flower2.png" alt="arrow" className="flower"/>メニュー
                </h3>
                <div className="about-item-text">
                  <strong>ソフトドリンク</strong>
                  <hr />
                  <ul>
                    <li>緑茶</li>
                    <li>炭酸水</li>
                    <li>烏龍茶</li>
                    <li>ミネラルウォーター</li>
                    <li>コーラ</li>
                    <li>紅茶（ホット）</li>
                    <li>オレンジジュース</li>
                    <li>アップルジュース</li>
                    <li>ジンジャーエール</li>
                    <li>トニックウォーター</li>
                  </ul>

                  <strong>アルコール</strong>
                  <hr />
                  <ul>
                    <li>焼酎（黒霧島・いいちこ・二階堂・鏡月）</li>
                    <li>ウィスキー</li>
                    <li>ジン</li>
                    <li>ミネラルウォーター</li>
                    <li>ウォッカ</li>
                    <li>ラム</li>
                    <li>カシス</li>
                    <li>ビール (＊別料金 500円)</li>
                  </ul>
                  ＊割り方各種お申し付けください。<br />
                  ＊スタッフドリンクもございます。<br />


                  <ul>
                    <li>スタッフドリンク 1,000円となります。</li>
                    <li>メニューにないドリンク等のご希望はスタッフにご相談ください。(追加料金となる場合もございます)</li>
                    <li>ボトルキープをしていただいているお客様がボトル以外のお飲み物を飲まれる場合は
                  一杯1,000円となります。</li>
                  </ul>
                  
                </div>
              </div>

              <div className="about-item" id="mitsu_event">
                <h3 className="about-item-title">
                  <img src="/assets/common/h1_flower2.png" alt="arrow" className="flower"/>密蜜縄講習会
                </h3>
                <div className="about-item-text">
                後手縛りの基礎を講師のもと学んで頂けます。<br />
                <br />
                毎週水曜日の21時から23時。<br />
                会費は3,000円。<br />
                <br />
                密蜜-mitsu-公式アカウント<br />
                <a href="https://twitter.com/mitsunawa">https://twitter.com/mitsunawa</a><br />
                こちらのDMへご連絡ください。<br />
                <br />
                メールでのお申し込みはこちら<br />
                bar.mitsu@gmail.com<br />
                </div>
              </div>

              <div className="about-item" id="mitsu_access">
                <h3 className="about-item-title">
                  <img src="/assets/common/h1_flower2.png" alt="arrow" className="flower"/>アクセス
                </h3>
                <div className="about-item-text">
                  <strong>住所</strong>
                  <hr />
                  〒160-0021<br />
                  東京都新宿区歌舞伎町1-4-12 ナカヨシビル4F<br />
                  <br />
                  <strong>アクセス</strong>
                  <hr />
                  電車: <br />
                  新宿駅 （東口より徒歩7分）<br />
                  新宿三丁目駅・東新宿駅・新宿西口駅・西武新宿駅<br />
                  <br />
                  バス: <br />
                  歌舞伎町、新宿五丁目、新宿駅東口<br />
                  <br />
                  徒歩: <br />
                  靖国通りから区役所通りに入り、30メートル程度<br />
                  <br />
                  <strong>電話番号</strong>
                  <hr />
                  <a href="tel:+8180-3912-3232">080-3912-3232</a><br />
                  <br />
                  <strong>メールアドレス</strong>
                  <hr />
                  <a href="mailto:bar.mitsu@gmail.com">bar.mitsu@gmail.com</a><br />
                  <br />
                  <div className="map-container">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.303331609334!2d139.7011810122243!3d35.69415247246878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cd99f00fea7%3A0x8a2223138b66028!2z44CSMTYwLTAwMjEg5p2x5Lqs6YO95paw5a6_5Yy65q2M6Iie5LyO55S677yR5LiB55uu77yU4oiS77yR77ySIOODiuOCq-ODqOOCt-ODk-ODqyA0Zg!5e0!3m2!1sja!2sjp!4v1737223421793!5m2!1sja!2sjp"
                      width="100%"
                      height="450"
                      style={{ border: 0 }}
                      allowFullScreen={true}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title="Google Map"
                    ></iframe>
                  </div>
                </div>
              </div>

              <div className="about-item" id="mitsu_corona">
                <h3 className="about-item-title">
                  <img src="/assets/common/h1_flower2.png" alt="arrow" className="flower"/>閉店後の店内環境消毒について
                </h3>
                <div className="about-item-text">
                当店では、お客様に安心してご利用いただけるよう、引き続き衛生管理を徹底しております。
                  <ul>
                    <li>営業時間中の定期的な換気および清掃</li>
                    <li>テーブルやドアノブなど、手が触れる箇所の消毒（アルコール・次亜塩素酸ナトリウム水溶液を使用）</li>
                    <li>閉店後の店内消毒として、紫外線殺菌灯を使用</li>
                  </ul>
                  紫外線殺菌灯は、病院や食品工場などでも一般的に使用されている衛生管理の方法であり、使用後の残留物はなく、お客様に影響はございません。
                  今後も皆さまに快適にお過ごしいただけるよう、衛生管理を継続してまいります。
                </div>
              </div>
            
                
                {/* <div className="container about-images-inner">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <img src="/assets/about/about001.jpg" alt="about" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <img src="/assets/about/about002.jpg" alt="about" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <img src="/assets/about/about003.jpg" alt="about" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <img src="/assets/about/about004.jpg" alt="about" />
                    </div>
                  </div>
                </div> */}

            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="container about-images">
                <div className="row">
                  {/* ギャラリーからランダムでピックアップ */}
                  <div className="col-sm-12 col-md-6 col-lg-4 about-image">
                    <img src="/assets/about/about005.jpg" alt="about" />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 about-image">
                    <img src="/assets/about/about006.jpg" alt="about" />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 about-image">
                    <img src="/assets/about/about001.jpg" alt="about" />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 about-image">
                    <img src="/assets/about/about007.jpg" alt="about" />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 about-image">
                    <img src="/assets/about/about008.jpg" alt="about" />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4 about-image">
                    <img src="/assets/about/about009.jpg" alt="about" />
                  </div>
                </div>
              </div>
            </div>


          
            
          </div>
        </div>
      <Footer />
    </>
  );
};

export default About;