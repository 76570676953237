import React, { useState, useEffect } from "react";
import axios from 'axios';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

const Schedule = () => {

  const [apiMitsuScheduleData, setApiMitsuScheduleData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("https://wp-rest-api.shigonawabingo.com/wordpress/wp-json/wp/v2/mitsumitsu_schedule");
      console.log(response.data);
      setApiMitsuScheduleData(response.data);
    };
  
    fetchData();
  }, []);

  return (
    <>
      <Nav />
      <div className="container schedule">
        <div className="row pt100 pb100">
          <h1 className="mb20">スケジュール</h1>
          <div className="col-lg-6 col-md-12 mb-3 content-column-c mb30">

            {/* {apiMitsuScheduleData.length} */}

            {/* {apiMitsuScheduleData.length > 0 ? (<></>) : (<></>)} */}

            {apiMitsuScheduleData.length > 0 ? (
              apiMitsuScheduleData.map((item, index) => {
                const date = new Date(item.date);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const day = ("0" + date.getDate()).slice(-2);
                const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                const weekday = weekdays[date.getDay()];
                return (
                  <div className="top-article" id={`schedule-article-${index}`}>
                    <h3>{`${month}.${day}`} <span className="wday">{weekday}</span> <span className="year">2025</span></h3>
                    {item.title.rendered && <div className="top-article-title">{item.title.rendered}</div>}
                    <div className="top-article-content" dangerouslySetInnerHTML={{ __html: item.content.rendered }} />
                  </div>
                  // <pre>{JSON.stringify(item, null, 2)}</pre>

                );
              })
            ) : (<>false</>)}

            {/* <div className="top-article">
              <h3>10.15 <span className="wday">tue</span></h3>
              <div className="top-article-title">密蜜-mitsu-の思い</div>
              <div className="top-article-text">
                2013年12月25日、クリスマスの夜に<br></br>
                「密蜜-mitsu-」オープンです!<br></br>
                多彩なゲストをお迎えしています。<br></br>
                どうぞおいでください。<br></br>
                詳しくはこちら
              </div>
            </div> */}

          </div>
          <div className="col-lg-6 col-md-9 mb-3 content-column-b">
            <div className="ad-area">
              <img src="/assets/common/pubphoto001.jpg" alt="schedule" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Schedule;