import Nav from '../components/Nav';
import Footer from '../components/Footer';

const Recruit = () => {
    return (
      <>
      <Nav />
        <div className="container about">
          <div className="row pt100 pb100">
            <h1 className="mb20">リクルート</h1>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="about-item">
                <h3 className="about-item-title">
                  <img src="/assets/common/h1_flower2.png" alt="arrow" className="flower"/>スタッフ募集
                </h3>
                <div className="about-item-text">
                  現在はリクルートの募集はお休みさせて頂いております。<br />
                  <br />
                  密蜜-mitsu-では、20歳以上で心身ともに健康で明るくSMに理解があり緊縛経験がある、
                  あるいは縛りを覚えたいと言う前向きな気持ちを持つ女性スタッフを募集しております。
                  店内研修/試用期間あり。なお、現在は臨時スタッフのみの募集となっております。
                  詳細は顔写真を添えて<a href="/contact">お問い合わせ</a>ください。<br />
                </div>
              </div>
            </div>
          </div>
        </div>
      <Footer />
      </>
    );
};
export default Recruit;