import React, { useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const Gallery = () => {
  const images = [
'/assets/gallery/2025-03-04_9.jpg',
'/assets/gallery/2025-03-04_8.jpg',
'/assets/gallery/2025-03-04_7.jpg',
'/assets/gallery/2025-03-04_6.jpg',
'/assets/gallery/2025-03-04_56.jpg',
'/assets/gallery/2025-03-04_55.jpg',
'/assets/gallery/2025-03-04_54.jpg',
'/assets/gallery/2025-03-04_53.jpg',
'/assets/gallery/2025-03-04_52.jpg',
'/assets/gallery/2025-03-04_51.jpg',
'/assets/gallery/2025-03-04_50.jpg',
'/assets/gallery/2025-03-04_5.jpg',
'/assets/gallery/2025-03-04_49.jpg',
'/assets/gallery/2025-03-04_48.jpg',
'/assets/gallery/2025-03-04_47.jpg',
'/assets/gallery/2025-03-04_46.jpg',
'/assets/gallery/2025-03-04_45.jpg',
'/assets/gallery/2025-03-04_44.jpg',
'/assets/gallery/2025-03-04_43.jpg',
'/assets/gallery/2025-03-04_42.jpg',
'/assets/gallery/2025-03-04_41.jpg',
'/assets/gallery/2025-03-04_40.jpg',
'/assets/gallery/2025-03-04_4.jpg',
'/assets/gallery/2025-03-04_39.jpg',
'/assets/gallery/2025-03-04_38.jpg',
'/assets/gallery/2025-03-04_37.jpg',
'/assets/gallery/2025-03-04_36.jpg',
'/assets/gallery/2025-03-04_35.jpg',
'/assets/gallery/2025-03-04_34.jpg',
'/assets/gallery/2025-03-04_33.jpg',
'/assets/gallery/2025-03-04_32.jpg',
'/assets/gallery/2025-03-04_31.jpg',
'/assets/gallery/2025-03-04_30.jpg',
'/assets/gallery/2025-03-04_3.jpg',
'/assets/gallery/2025-03-04_29.jpg',
'/assets/gallery/2025-03-04_28.jpg',
'/assets/gallery/2025-03-04_27.jpg',
'/assets/gallery/2025-03-04_26.jpg',
'/assets/gallery/2025-03-04_25.jpg',
'/assets/gallery/2025-03-04_24.jpg',
'/assets/gallery/2025-03-04_23.jpg',
'/assets/gallery/2025-03-04_22.jpg',
'/assets/gallery/2025-03-04_21.jpg',
'/assets/gallery/2025-03-04_20.jpg',
'/assets/gallery/2025-03-04_2.jpg',
'/assets/gallery/2025-03-04_19.jpg',
'/assets/gallery/2025-03-04_18.jpg',
'/assets/gallery/2025-03-04_17.jpg',
'/assets/gallery/2025-03-04_16.jpg',
'/assets/gallery/2025-03-04_15.jpg',
'/assets/gallery/2025-03-04_14.jpg',
'/assets/gallery/2025-03-04_13.jpg',
'/assets/gallery/2025-03-04_12.jpg',
'/assets/gallery/2025-03-04_11.jpg',
'/assets/gallery/2025-03-04_10.jpg',
'/assets/gallery/2025-03-04_1.jpg',
];
  

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <>
      <Nav />
      <div className="container gallery">
        <div className="row pt100 pb100">
          <h1 className="mb20">Gallery</h1>
            <div className="col-sm-12 col-md-12 col-lg-12">
            {images.map((image, index) => (
              <div className="image-thumbnails" key={index}>
                <img
                  src={image}
                  alt={`about-${index}`}
                  className="img-thumbnail"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#imageModal"
                  onClick={() => setCurrentIndex(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Bootstrap モーダル */}
      <div
        className="modal fade gallery-modal"
        id="imageModal"
        tabIndex="-1"
        aria-labelledby="imageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5 className="modal-title">Image {currentIndex + 1}</h5> */}

                <div className="gallery-close-button flex-end">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

            </div>
            <div className="modal-body text-center">
              <img src={images[currentIndex]} alt={`about-${currentIndex}`} className="img-fluid" />
            </div>
            <div className="modal-footer justify-content-end">
              <button type="button" className="btn btn-secondary" onClick={prevImage}>
                ◀ Previous
              </button>
              <button type="button" className="btn btn-secondary" onClick={nextImage}>
                Next ▶
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
