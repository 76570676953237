import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
// import { createClient } from "microcms-js-sdk";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/main.scss';
// import backgroundMitsu from '../assets/images/mitsu.jpg';
import backgroundSalon from '../assets/images/salon.jpg';
import backgroundShigonawabingo from '../assets/images/shigonawa.jpg';
import backgroundBackdoor from '../assets/images/backdoor.jpg';

import Nav from '../components/Nav';
import Footer from '../components/Footer';
// import { use } from "react";
import { Modal, Button } from 'react-bootstrap';

// const client = createClient({
//   serviceDomain: "ztn5pjtobd",
//   apiKey: "YBNJ1MrW7XXop6nn4RHB1dBbFSQe23EVwYxU",
// });

const Top = () => {
  const [showText1, setShowText1] = useState(false);
  const [showText2, setShowText2] = useState(false);

  const [showModal, setShowModal] = useState(false);
  // const [message, setMessage] = useState("");
  const [apiMitsuScheduleData, setApiMitsuScheduleData] = useState([]);
  const images = [
    "../assets/images/tops/hero1.jpg",
    "../assets/images/tops/hero2.jpg",
    "../assets/images/tops/hero3.jpg",
    "../assets/images/tops/hero4.jpg",
    "../assets/images/tops/hero5.png",
    "../assets/images/tops/hero6.jpg"
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const twitterRef = useRef(null);

  /* 18歳未満の方へのアクセス制限 */
  useEffect(() => {
    const confirmationStatus = localStorage.getItem("confirmation_status");
    if (confirmationStatus !== "true") {
      setShowModal(true);
    }
  }, []);

  const handleConfirm = () => {
    localStorage.setItem("confirmation_status", "true");
    setShowModal(false);
  };

  const handleReject = () => {
    localStorage.setItem("confirmation_status", "false");
    window.location.href = "https://www.yahoo.co.jp";
  };
  /* 18歳未満の方へのアクセス制限 end */

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [images.length]);

  // useEffect(() => {
  //   client
  //     .get({
  //       endpoint: 'news',
  //     })
  //     .then((res) => {
  //       setMessage(res.contents[0].title);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);

  useEffect(() => {
    // すでに `widgets.js` がロードされているかチェック
    if (!window.twttr) {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      script.charset = "utf-8";
      script.onload = () => {
        window.twttr.widgets.load(twitterRef.current);
      };
      document.body.appendChild(script);
    } else {
      // すでにロード済みなら、ウィジェットを適用
      window.twttr.widgets.load(twitterRef.current);
    }
  }, []);


  // https://wp-rest-api.shigonawabingo.com/wordpress/wp-json/wp/v2/mitsumitsu_schedule

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("https://wp-rest-api.shigonawabingo.com/wordpress/wp-json/wp/v2/mitsumitsu_schedule");
      console.log(response.data);
      setApiMitsuScheduleData(response.data);
    };
  
    fetchData();
  }, []);

useEffect(() => {
    let text1Timer, text2Timer, resetTimer;

    // 2秒後に fading-text-1 を表示
    text1Timer = setTimeout(() => {
      setShowText1(true);
    }, 2000);

    // その1秒後（合計3秒後）に fading-text-2 を表示
    text2Timer = setTimeout(() => {
      setShowText2(true);
    }, 5000);

    // 10秒後に両方を再度非表示にする（合計13秒後）
    resetTimer = setTimeout(() => {
      // setShowText1(false);
      // setShowText2(false);
    }, 50000);

    // クリーンアップ
    return () => {
      clearTimeout(text1Timer);
      clearTimeout(text2Timer);
      clearTimeout(resetTimer);
    };
  }, []);



  return (
    <>
      <Nav />
      <div className="main-vidual">
        
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide inset-0 transition-opacity duration-1000 ${
              index === currentImageIndex ? "opacity-100" : "opacity-0"
            }`}
          >
            <img src={image} alt="紫護縄びんご" />
          </div>
        ))}

        <div className="main-visual-overlay">
          <div className="main-visual-bigcopy">
            {/* <h2 className="main-visual-title">密蜜 -mitsu-</h2> */}
            <p className="main-visual-subtitle mt100">歌舞伎町 和風SMバー</p>
            <div class="flex-container">
              <img src="/assets/images/shop_logo1.png" class="mv-logo" alt="" />
            </div>

            <div className={`fading-text-1 text-center mt50 ${showText1 ? "fade-in" : "fade-out"}`}>
              <p>
                歌舞伎町に佇む和風SMバー<br />
                「密蜜 - mitsu -」<br />
                新しい自分を発見できる場所として、非日常的な体験を楽む場所とて
                </p>
            </div>

            <div className={`fading-text-2 text-center ${showText2 ? "fade-in" : "fade-out"}`}>
              初心者の方には安心を、上級者の方にはよりディープに
            </div>
          
          </div>
        </div>
      </div>

        <div className="container index-area">
          <div className="row">

            {/* スケジュール */}
            <div className="col-lg-6 col-md-12 mb-3 content-column-c mb30">
              <h2 className="mb30">スケジュール</h2>
              {/* <pre>{JSON.stringify(apiMitsuScheduleData, null, 2)}</pre> */}

              {/* <div class="top-article" id="schedule-article-0">
                <h3>02.10 <span class="wday">Mon</span></h3>
                <div class="top-article-title">スケジュールテスト2025-02-12</div>
                <div>
                  <img src="/assets/images/schedule_dummy.jpg" alt="xxx" width="100%"/>
                </div>
              </div> */}
              

              {apiMitsuScheduleData.length > 0 ? (
                apiMitsuScheduleData.map((data, index) => {
                    return (
                    <div className="top-article" id={`schedule-article-${index}`}>
                      {(() => {
                      const date = new Date(data.date);
                      const month = ("0" + (date.getMonth() + 1)).slice(-2);
                      const day = ("0" + date.getDate()).slice(-2);
                      const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                      const weekday = weekdays[date.getDay()];
                        return (
                        <>
                        <h3>{`${month}.${day}`} <span className="wday">{weekday}</span> <span className="year">2025</span></h3>
                        {/* {data.title.rendered} */}
                        {data.title.rendered && <div className="top-article-title">{data.title.rendered}</div>}

                        {/* <div dangerouslySetInnerHTML={{ __html: data.content.rendered.replace(/(https?:\/\/[^\s<]+)/g, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>') }} /> */}
                        <div className="top-article-content" dangerouslySetInnerHTML={{ __html: data.content.rendered }} />

                        </>
                        );
                      })()}
                    </div>
                    );
                })
                ) : (
                <>nothing</>
                )}
                <div className="archive-link"><a href="/schedule">Schedule Archives</a></div>
              </div>

              {/* 更新履歴 */}
            <div className="col-lg-6 col-md-9 mb-3 content-column-b">
              <h2 className="mb30">News & Event</h2>

              <div className="top-article">
                <h3>01.19 <span className="wday" style={{ fontSize: '14px' }}>tue</span></h3>
                <div className="top-article-title">密蜜-mitsu-の思い</div>
                <div className="top-article-text">
                    「自分たちが素晴らしいと思える、 この世界を知らない方達にも 知ってもらいたい」 という思いではじめました。<br></br>
                  その言葉には沢山の 要素を含んでいます<br></br>
                  ここでは誰もあなたを否定しません。<br></br>
                  あなたの素晴らしい世界を 密蜜-mitsu-で見つけてください。
                </div>
              </div>

              <div className="top-article">
                <h3>01.20 <span className="wday">tue</span></h3>
                <div className="top-article-title">密蜜-mitsu-の思い</div>
                <div className="top-article-text">
                  昼間なら遊びに行けるのに・・の声にお応えして、「日曜密蜜」始まってます！<br></br>
                  日曜日の昼下がりもいつもの密蜜-mitsu-でお会いしましょう。<br></br>
                  営業時間 13:00～19:00<br></br>

                  19:00～24:00は、ふぅの密蜜弁財天<br></br>
                  <a href="http://bar-mitsu.com" target="_blank" rel="noopener noreferrer">http://bar-mitsu.com</a>
                  <br></br>
                  ※通常営業とシステムが変わります。
                </div>
              </div>
                
              <div className="top-article">
                <h3>01.21 <span className="wday">tue</span></h3>
                <div className="top-article-title">密蜜-mitsu-の思い</div>
                <div className="top-article-text">
                  2013年12月25日、クリスマスの夜に<br></br>
                  「密蜜-mitsu-」オープンです!<br></br>
                  多彩なゲストをお迎えしています。<br></br>
                  どうぞおいでください。<br></br>
                  詳しくはこちら
                </div>
              </div>
            </div>


          </div>
        </div>




      <div className="container second-area">
        <div className="row">
          <div className="col-md-4">
            {/* <a href="https://shigonawabingo.com/" target="_blank" rel="noreferrer"> */}
            <a href="/" target="_blank" rel="noreferrer">
              <div
                style={{ backgroundImage: `url(${backgroundShigonawabingo})` }}
                className="shigog"
              >
                <h3 className="shigog-title">紫護縄びんご.com</h3>
                <div
                  className="coming-soon"
                  style={{ textAlign: `center`, fontSize: `30px`, fontWeight: `bold`, backgroundColor: `rgba(0, 0, 0, 0.7)`, padding: `20px`, borderRadius: `10px` }}
                >
                  Coming soon
                </div>
                <p className="shigog-summary mt20">紫護縄びんごのSM<br />
                哲学・技術、そのすべて</p>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a href="https://salon.shigonawabingo.com/" target="_blank" rel="noreferrer">
              <div
                style={{ backgroundImage: `url(${backgroundSalon})` }}
                className="shigog"
              >
                {/* <p className="shigog-pre-title">体験型SMサロン</p> */}
                <h3 className="shigog-title">紫護縄SALON</h3>
                <div
                  className="coming-soon"
                  style={{ textAlign: `center`, fontSize: `30px`, fontWeight: `bold`, backgroundColor: `rgba(0, 0, 0, 0.7)`, padding: `20px`, borderRadius: `10px` }}
                >
                  Coming soon
                </div>
                <p className="mt20">
                  解放と赦しを求めて<br />
                  ここに「体験」の極がある
                </p>
              </div>
            </a>
          </div>
          <div className="col-md-4">
            <a href="https://backdoor.shigonawabingo.com/" target="_blank" rel="noreferrer"> 
              <div className="shigog" style={{ backgroundImage: `url(${backgroundBackdoor})` }}>
                {/* <p>歌舞伎町和風BAR</p> */}
                <h3>BACK DOOR</h3>
                <div
                  className="coming-soon"
                  style={{ textAlign: `center`, fontSize: `30px`, fontWeight: `bold`, backgroundColor: `rgba(0, 0, 0, 0.7)`, padding: `20px`, borderRadius: `10px` }}
                >
                  Coming soon
                </div>
                <p>SM という秘め事<br />
                その深淵を覗く扉</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="container third-area">
        <div className="row">
          <div className="col-md-6">
            {/* <h4 className="info-title">News</h4>
            <ul className="information">
              <li>{message}</li>
              <li>2024-09-30 [紫護縄サロン] スケジュール変更...</li>
              <li>2024-09-30 [密蜜] 紫護縄びんごをよろしく...</li>
              <li>2024-09-30 紫護縄びんご new site オープン...</li>
            </ul> */}
          </div>
          <div className="col-md-6">
            <h4 className="info-title">お知らせ</h4>
            <div ref={twitterRef} style={{ padding: "30px" }}>
              <a
                className="twitter-timeline"
                data-width="500"
                data-height="500"
                data-dnt="true"
                data-theme="dark"
                href="https://x.com/mitsunawa"
                target="_blank"
                rel="noreferrer"
              >
                Tweets by 密蜜-mitsu-
                {/* https://twitter.com/shigonawa */}
                {/* https://x.com/mitsunawa */}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container third-area mb120">
        <div className="row">
          <div className="col-12">
            {/* continue... */}
          </div>
        </div>
      </div>

      {/* 18禁確認モーダル */}
      <Modal show={showModal} backdrop="static" keyboard={false} className="confirmation18-modal">
        <Modal.Header>
          <Modal.Title>年齢確認</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          このサイトは18歳以上の方のみ閲覧可能です。<br />あなたは18歳以上ですか？
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary shigonawa-btn-secondary" onClick={handleReject}>18歳未満</Button>
          <Button variant="primary shigonawa-btn-primary" onClick={handleConfirm}>18歳以上</Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </>
  );
};

export default Top;
