import { useEffect, useState, useRef } from "react";
import routes from "../routes";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(true);
  const timeoutIdRef = useRef(null); // useRef を使用して timeoutId を管理

  useEffect(() => {
    const handleScroll = () => {
      // スクロール中は非表示
      setIsVisible(false);

      // スクロールが停止したら一定時間後に表示
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current); // 前回のタイマーをクリア
      }
      timeoutIdRef.current = setTimeout(() => {
        setIsVisible(true);
      }, 300); // スクロール停止後 300ms で表示
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // クリーンアップ
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`footer container-fluid second-area footer-pc ${
          isVisible ? "visible" : "hidden"
        }`}
      >
        <div className="footerbar"></div>
        <div className="footer-section">
          <div className="footer-section-item">
            <ul>
              <li>
                <a href={routes.top}>Home</a>
              </li>
              <li>
                <a href={routes.about}>About</a>
              </li>
              <li>
                <a href={routes.contact}>Contact</a>
              </li>
              <li>
                <a href={routes.terms_of_use}>ご利用規約</a>
              </li>
              <li>
                <a href={routes.privacy_policy}>プライバリーポリシー</a>
              </li>
            </ul>
            <p className="copyright">© shigonawabingo.com</p>
          </div>
        </div>
      </div>

      <div
        className={`footer container-fluid second-area footer-sp ${
          isVisible ? "visible" : "hidden"
        }`}
      >
        <ul>
          <li>
            <a href={routes.top}>Home</a>
          </li>
          <li>
            <a href={routes.about}>About</a>
          </li>
          <li>
            <a href={routes.contact}>Contact</a>
          </li>
          <li>
            <a href={routes.terms_of_use}>ご利用規約</a>
          </li>
          <li>
            <a href={routes.privacy_policy}>プライバリーポリシー</a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Footer;
