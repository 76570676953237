import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/main.scss';

import Nav from '../components/Nav';
import Footer from '../components/Footer';

const TermsOfUse = () => {
	return (
		<>
      <Nav />
      <div className="container simple-page">
        <div className="row pt100 pb100">
          <div className="col-12">
            <div className="simple-page-block">
              <h1 className="mb60">Term of use</h1>

              <div className="simple-page-section">
                <h2>ご利用規約</h2>
                <div class="paragraph">
                  <p>
                    本利用規約（以下「本規約」）は、SM Bar 密蜜（以下「本サービス」）の利用条件を定めるものです。本サービスをご利用いただくにあたり、すべてのユーザー（以下「ユーザー」）は本規約に同意した上で、本サービスを利用するものとします。本規約は、ユーザーと運営者との間の本サービスに関する一切の関係に適用されます。
                  </p>
                </div>
              </div>

              <div className="simple-page-section">
                <h2>第1条（適用範囲）</h2>
                <div class="paragraph">
                  <p>
                    当サービスは、ユーザーの皆さまにより良いサービスを提供するため、以下の目的で個人情報を収集いたします。個人情報とは、氏名、メールアドレス、住所、電話番号、その他個人を特定できる情報を指します。
                  </p>
                  <ol>
                    <li>本規約は、本サービスを利用する全てのユーザーに適用され、ユーザーが本サービスを利用するにあたり遵守すべき事項を定めています。</li>
                    <li>ユーザーは、本サービスを利用することで、本規約のすべての条項に同意したものとみなされます。</li>
                    <li>本規約に規定されていない事項については、運営者が別途定めるガイドライン、注意事項、または法令等の規定が適用されるものとします。</li>
                  </ol>
                </div>
              </div>
                
              <div className="simple-page-section">
                <h2>第2条（利用登録について）</h2>
                <div class="paragraph">
                  <ol>
                    <li>登録情報の提供<br />本サービスを利用するためには、ユーザーは運営者が定める方法に従い、必要な情報を正確かつ最新の内容で提供し、利用登録を行うものとします。</li>
                    <li>登録の承認および拒否<br />運営者は、以下の場合において、ユーザーの登録申請を承認しない、または取り消すことがあります。
                      <ul>
                        <li>登録情報に虚偽の内容が含まれている場合</li>
                        <li>過去に本規約に違反したことがあると運営者が判断した場合</li>
                        <li>その他、運営者が適切でないと判断した場合</li>
                      </ul>
                    </li>
                    <li>不正利用防止のための調査<br />アカウントの安全性を確保し、不正アクセスや悪用を防ぐために調査を行います。</li>
                    <li>登録内容の変更<br />ユーザーは、登録内容に変更が生じた場合、速やかに運営者が定める手続きにより変更を届け出るものとします。</li>
                  </ol>
                </div>
              </div>

              <div className="simple-page-section">
                <h2>第3条（禁止事項）</h2>
                <div class="paragraph">
                  <p>
                    ユーザーは、本サービスを利用するにあたり、以下の行為を行ってはなりません。
                  </p>
                  <ol>
                    <li>法令または公序良俗に違反する行為<br />犯罪行為、または犯罪行為を助長する行為、他者の名誉、信用、プライバシーを侵害する行為</li>
                    <li>技術的妨害行為<br />サーバーやネットワークの機能を破壊または妨害する行為、ウイルス等の有害なプログラムを送信する行為</li>
                    <li>他者の権利侵害<br />他のユーザーや第三者の個人情報、知的財産権を無断で収集、利用、または公開する行為</li>
                    <li>その他運営者が不適切と判断する行為<br />本サービスの趣旨に反する行為、運営者が迷惑または不利益を被る行為</li>
                  </ol>
                </div>
              </div>

              <div className="simple-page-section">
                <h2>第4条（サービスの提供の停止および中断）</h2>
                <div class="paragraph">
                  <p>
                    当サービスでは、ユーザーの個人情報を適切に保護するために、以下の対策を講じています。
                  </p>
                  <ol>
                    <li>停止および中断の事由<br />運営者は、以下の事由に該当する場合、ユーザーへの事前通知なく、本サービスの全部または一部を停止または中断することがあります。
                      <ul>
                        <li>定期メンテナンス、緊急メンテナンス、システム障害、天災などによる場合</li>
                        <li>火災、停電、天災などの不可抗力によって本サービスの提供が困難になった場合</li>
                        <li>その他、運営者が停止または中断を必要と判断した場合</li>
                      </ul>
                    </li>
                    <li>免責事項<br />本サービスの提供停止または中断によってユーザーまたは第三者に生じた損害について、運営者は一切の責任を負わないものとします。</li>
                  </ol>
                </div>
              </div>

              <div className="simple-page-section">
                <h2>第5条（利用規約の変更について）</h2>
                <div class="paragraph">
                  <ol>
                    <li>変更の権利<br />運営者は、必要と判断した場合、本規約の内容を予告なく変更することができるものとします。</li>
                    <li>変更後の効力<br />変更後の本規約は、本サービス上に掲載された時点で効力を生じるものとします。ユーザーが変更後も本サービスを利用し続ける場合、変更後の規約に同意したものとみなされます。</li>
                  </ol>
                </div>
              </div>

              <div className="simple-page-section">
                <h2>第6条（免責事項）</h2>
                <div class="paragraph">
                  <ol>
                    <li>サービスの保証範囲<br />本サービスは、現状有姿で提供され、運営者は本サービスにおける完全性、正確性、有用性について何ら保証しません。</li>
                    <li>損害賠償の制限<br />本サービスの利用により発生した損害について、運営者は一切の責任を負わないものとします。ただし、運営者に故意または重大な過失がある場合を除きます。</li>
                    <li>第三者サービスの利用<br />本サービスは、第三者が提供するサービスやリンクを含む場合がありますが、運営者はその内容について一切責任を負いません。</li>
                  </ol>
                </div>
              </div>

              <div className="simple-page-section">
                <h2>第7条（準拠法および裁判管轄）</h2>
                <div class="paragraph">
                  <p>
                    本ポリシーや個人情報の取扱いに関するお問い合わせについては、以下のお問い合わせからご連絡ください。
                  </p>
                  <div class="paragraph">
                  <ol>
                    <li>準拠法<br />本規約の解釈および適用に関しては、日本法を準拠法とします。</li>
                    <li>専属的合意管轄<br />本サービスに関する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄とします。</li>
                  </ol>
                  以上
                </div>
              </div>

              <div className="simple-page-section">
                <p className="date text-end">2025年1月19日 更新</p>
              </div>

            </div>

            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;