import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/main.scss';

import Nav from '../components/Nav';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
	return (
    <>
      <Nav />
        <div className="container simple-page">
          <div className="row pt100 pb100">
            <div className="col-12">
              <div className="simple-page-block">
                <h1 className="mb60">PrivacyPolicy</h1>

                <div className="simple-page-section">
                  <h2>プライバシーポリシー</h2>
                  <div class="paragraph">
                    <p>
                      本プライバシーポリシー（以下「本ポリシー」）は、当サービスがユーザーの個人情報をどのように収集、利用、管理し、保護するかを詳細に定めるものです。当サービスをご利用いただくことで、本ポリシーに同意したものとみなされます。本ポリシーは以下の内容を含みます。
                    </p>
                  </div>
                </div>

                <div className="simple-page-section">
                  <h2>第1条（個人情報の収集について）</h2>
                  <div class="paragraph">
                    <p>
                      当サービスは、ユーザーの皆さまにより良いサービスを提供するため、以下の目的で個人情報を収集いたします。個人情報とは、氏名、メールアドレス、住所、電話番号、その他個人を特定できる情報を指します。
                    </p>
                    <ol>
                      <li>サービス提供・運営<br />ユーザーが当サービスを快適にご利用いただけるよう、必要な機能やサービスを提供・運営するため。</li>
                      <li>サービス提供・運営<br />ユーザーから寄せられるご意見やお問い合わせに迅速かつ適切に対応するため。</li>
                      <li>不正行為への対応<br />当サービスの利用規約に違反する行為や不正利用を防止し、これに適切に対処するため。</li>
                      <li>その他付随する目的<br />上記の目的に関連し、ユーザー体験を向上させるために必要な情報を収集する場合があります。</li>
                    </ol>
                  </div>
                </div>
                
                <div className="simple-page-section">
                  <h2>第2条（個人情報の利用について）</h2>
                  <div class="paragraph">
                    <p>
                      収集した個人情報は、以下の目的のために適切に利用されます。当サービスでは、ユーザーの同意なく利用目的を超えて個人情報を利用することはありません。
                    </p>
                    <ol>
                      <li>サービスの改善および新機能の提供<br />ユーザーの意見や利用状況を分析し、サービスの品質向上や新たな機能の開発に活用します。</li>
                      <li>お知らせやメールマガジンの送付<br />メールや通知を通じて、サービスに関する重要なお知らせやキャンペーン情報などをお届けします。</li>
                      <li>不正利用防止のための調査<br />アカウントの安全性を確保し、不正アクセスや悪用を防ぐために調査を行います。</li>
                      <li>統計情報の作成<br />個人を特定できない形で統計情報を作成し、サービス運営の参考にする場合があります。</li>
                    </ol>
                  </div>
                </div>

                <div className="simple-page-section">
                  <h2>第3条（個人情報の第三者提供について）</h2>
                  <div class="paragraph">
                    <p>
                      当サービスは、ユーザーの個人情報を慎重に管理し、以下の場合を除き、事前の同意なく第三者に提供することはありません。
                    </p>
                    <ol>
                      <li>法令に基づく場合<br />法律に基づく要請や裁判所など公的機関からの要請に応じる必要がある場合。</li>
                      <li>人命・財産保護のため<br />人の生命、身体、または財産の保護が必要で、ユーザーの同意を得ることが困難である場合。</li>
                      <li>サービス提供に必要な場合<br />外部サービスとの連携が必要な際には、契約に基づき適切に管理された委託先に限り情報を共有する場合があります。</li>
                    </ol>
                  </div>
                </div>

                <div className="simple-page-section">
                  <h2>第4条（個人情報の安全管理について）</h2>
                  <div class="paragraph">
                    <p>
                      当サービスでは、ユーザーの個人情報を適切に保護するために、以下の対策を講じています。
                    </p>
                    <ol>
                      <li>技術的安全管理措置<br />個人情報への不正アクセスを防ぐため、暗号化技術やファイアウォールの使用などを実施します。</li>
                      <li>組織的安全管理措置<br />従業員に対して個人情報保護の重要性を周知し、適切な管理体制を整備します。</li>
                      <li>物理的安全管理措置<br />個人情報が保存される設備のアクセス制限や、不要な個人情報の適切な廃棄を行います。</li>
                    </ol>
                  </div>
                </div>

                <div className="simple-page-section">
                  <h2>第5条（個人情報の開示、訂正、削除について）</h2>
                  <div class="paragraph">
                    <p>
                      ユーザーは、当サービスが保有するご自身の個人情報について、以下の請求を行うことができます。
                    </p>
                    <ol>
                      <li>開示請求<br />ご自身の個人情報がどのように管理・利用されているか確認するために開示を求めることができます。</li>
                      <li>訂正請求<br />登録情報に誤りがある場合は、その訂正を求めることができます。</li>
                      <li>削除請求<br />利用終了後や不要となった情報の削除を希望する場合には、運営者が速やかに対応いたします。</li>
                    </ol>
                  </div>
                </div>

                <div className="simple-page-section">
                  <h2>第6条（プライバシーポリシーの変更について）</h2>
                  <div class="paragraph">
                    <p>
                      本ポリシーは、サービスの運営状況や法令の改正に応じて変更されることがあります。
                    </p>
                    <ol>
                      <li>事前通知なしの変更<br />本ポリシーは、予告なく変更される場合があります。変更後のポリシーは、本サービス上に掲示された時点から効力を生じるものとします。</li>
                      <li>重大な変更の際の対応<br />個人情報の取扱いに関する重大な変更が行われる場合には、適切な手段でユーザーに通知します。</li>
                    </ol>
                  </div>
                </div>

                <div className="simple-page-section">
                  <h2>第7条（お問い合わせについて）</h2>
                  <div class="paragraph">
                    <p>
                      本ポリシーや個人情報の取扱いに関するお問い合わせについては、以下のお問い合わせからご連絡ください。
                    </p>
                    <div class="contact-info">
                      <a href="/contact">お問い合わせ</a>
                    </div>
                  </div>
                </div>

                <div className="simple-page-section">
                  <p className="date text-end">2025年1月19日 更新</p>
                </div>

              </div>
            
            </div>
          </div>
        </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;