import Nav from '../components/Nav';
import Footer from '../components/Footer';
// import { Link } from 'react-router-dom';

const Staffs = () => {
	return (
		<>
      <Nav />
      <div className="container staffs">
        <div className="row pt100 pb100">

          <div className="col-sm-12 col-md-6 col-lg-4 staff-block mb20">
            <div class="card staff-card">
              <img src="/assets/staffs/bingo-san.jpg" className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <h4>紫護縄びんご <span className="owner">Owner</span></h4>
                  <p>
                    SM bar 密蜜-mitsu-・紫護縄サロンのオーナー。<br />
                    自身の本質は BDSMplayer であるとする同氏の持つ世界観に魅了された人々の声に推され、SM ライブショーを多数開催。<br />
                    国内はもとより、海外での活動も定着している。<br />
                    緊縛講習会や講座による後進の育成にも積極的に取り組んでいる。<br />
                    {/* SM bar 密蜜-mitsu-・初心-UBU-のオーナー。
                  SM bar 密蜜-mitsu-・紫護縄サロンのオーナー。
自身の本質は BDSMplayer であるとする同氏の持つ世界観に魅了された人々の声に推され、SM ライブショーを多数開催。
国内はもとより、海外での活動も定着している。
緊縛講習会や講座による後進の育成にも積極的に取り組んでいる。 */}
                  </p>
                  <a href="https://x.com/shigonawa" target="_blank" rel="noreferrer" style={{ color: '#fff' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32zzw" fill="#000" class="bi bi-twitter-x" viewBox="0 0 16 16">
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 staff-block">
            <div className="card staff-card">
              <img src="/assets/staffs/hu-san.jpg" className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <h4>ふぅ<span className="staff">Staff</span></h4>
                    琵琶奏者・緊縛師。女の子を縛ると、どうしても琵琶を弾きたくなる変態。琵琶の音だけで妊娠させたいと本気で思っている( ◜ω◝و(و　劇場などでは、女の子を縛って晒して見せ物にして琵琶を弾いている。緊縛は才勝氏に師事。好きな言葉は「おっぱい」。目標は「東京をもっと卑猥に！」
                  {/* 
                  琵琶奏者・緊縛師。女の子を縛ると、どうしても琵琶を弾きたくなる変態。琵琶の音だけで妊娠させたいと本気で思っている( ◜ω◝و(و　劇場などでは、女の子を縛って晒して見せ物にして琵琶を弾いている。緊縛は才勝氏に師事。好きな言葉は「おっぱい」。目標は「東京をもっと卑猥に！」<br /><br />
                  */}
                </p>
                <a href="https://x.com/minamotm" target="_blank" rel="noreferrer" style={{ color: '#fff' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32zzw" fill="#000" class="bi bi-twitter-x" viewBox="0 0 16 16">
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 staff-block">
            <div className="card staff-card">
              <img src="/assets/staffs/suzuki-reiji-san.jpg" className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <h4>スズキレイジ<span className="staff">Staff</span></h4>
                  <p>
                    緊縛写真作家スズキレイジによる耽美な緊縛と撮影をもれなく体験できる女性限定のお茶会「LazyCAFE」を主催。毎月第2、第4土曜日の13時から18時まで、 密蜜-mitsu-にて完全予約制で開催している。 詳細は<a href="https://www.ropemagic.net/salon/" target="_blank"  rel="noreferrer">https://www.ropemagic.net/salon/</a> まで。

                    {/* 「LazyCAFE」は緊縛写真作家スズキレイジによる耽美な緊縛と撮影をもれなく体験できる女性限定のお茶会です（禁煙）。毎月第2、第4土曜日の13時から18時まで、完全予約制で開催。
                  くわしくは <a href="https://www.ropemagic.net/salon/" target="_blank"  rel="noreferrer">https://www.ropemagic.net/salon/</a> をご覧ください。 */}
                  </p>
                </p>
                <a href="https://x.com/rope_magic" target="_blank" rel="noreferrer" style={{ color: '#fff' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32zzw" fill="#000" class="bi bi-twitter-x" viewBox="0 0 16 16">
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                  </a>
              </div>
            </div>
          </div>



          <div className="col-sm-12 col-md-6 col-lg-4 staff-block">
            <div className="card staff-card">
              <img src="/assets/staffs/hina-san.jpg" className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <h4>ひいな<span className="staff">Staff</span></h4>
                    2024年5月入店<br />
                    密蜜-mitsu-のお客様からスタッフへと転身。受け手、ショーモデルとしても活動中。幼少期から自縛していた生粋のBDSMプレイヤー。紫護縄びんごのもとで縛りの習得中。趣味は自宅シーシャ、料理＆お菓子作り、ピアス（身体改造）、長風呂。嫌いなものは虫と電流プレイ。

                  {/* 1995年3月生まれ<br />
                  2024年5月入店<br />
                  もともとは密蜜-mitsu-のお客さん。受け手、ショーモデルとしても活動中。幼少期から自縛していた生粋のBDSMプレイヤー。紫護縄びんごのもとで縛りの習得中。趣味は自宅シーシャ、料理＆お菓子作り、ピアス（身体改造）、長風呂。嫌いなものは虫と電流プレイ。 */}
                </p>
                  <a href="https://x.com/hyina_mitsu" target="_blank" rel="noreferrer" style={{ color: '#fff' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32zzw" fill="#000" class="bi bi-twitter-x" viewBox="0 0 16 16">
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                  </a>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 staff-block">
            <div className="card staff-card">
              <img src="/assets/staffs/arata-san.jpg" className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <h4>アラタ<span className="staff">Staff</span></h4>
                    2022年1月入店<br />
                    縄に興味を持ち初期は一縄会にて習う。<br />
                    密蜜-mitsu-のお客様からスタッフへと転身し<br />
                    現在は紫護縄びんご氏の元で修行中。<br />
                    主に飾り縄などアート縄を得意とし、日々新しい飾り縄を自身で考案中。<br />

                  {/* 1981年4月生まれ<br />
                  2022年1月入店<br />
                  縄に興味を持ち初期は一縄会にて習う。<br />
                  密蜜-mitsu-のお客さんからスタッフへ。<br />
                  現在は紫護縄びんご氏の元で修行中。<br />
                  主に飾り縄などアート縄を得意とし、日々新しい飾り縄を自身で考案中。<br /><br /><br /><br /> */}
                  
                </p>
                <a href="https://x.com/arata202020" target="_blank" rel="noreferrer" style={{ color: '#fff' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32zzw" fill="#000" class="bi bi-twitter-x" viewBox="0 0 16 16">
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                  </a>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 staff-block">
            <div className="card staff-card">
              <img src="/assets/staffs/staff-someon.png" className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text">
                  <h4>はっぱ隊長<span className="staff">Staff</span></h4>
                  Coming soon<br /><br /><br /><br /><br /><br /><br /><br /></p>
                  <a href="https://x.com/inhuman134" target="_blank" rel="noreferrer" style={{ color: '#fff' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32zzw" fill="#000" class="bi bi-twitter-x" viewBox="0 0 16 16">
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                  </a>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};
export default Staffs;