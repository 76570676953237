const routes = {
    top: "/",
    contact: "/contact",
    about: "/about",
    schedule: "/schedule",
    news: "/news",
    staffs: "/staffs",
    recruit: "/recruit",
    gallery: "/gallery",
    privacy_policy: "/privacy_policy",
    terms_of_use: "/terms_of_use",
    list: "/list",
    /** develop */
    googlecalendar: "/googlecalendar",
  };
  
export default routes;