import React, { useEffect, useState } from 'react';
import '../scss/main.scss';
import routes from '../routes';

const Nav = () => {
  // サイドバーの開閉状態を管理するステート
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // サイドバーのトグル関数
  const toggleSidebar = () => {
    setSidebarOpen((prevState) => !prevState); // 状態を前の状態から反転
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = "hidden"; // スクロールを無効化
    } else {
      document.body.style.overflow = ""; // 元に戻す
    }
    
    return () => {
      document.body.style.overflow = ""; // クリーンアップ
    };
  }, [isSidebarOpen]);


  useEffect(() => {
    const sidebarLinks = document.querySelectorAll('.sidebar a');
    sidebarLinks.forEach(link => {
      link.addEventListener('click', toggleSidebar);
    });
  
    return () => {
      sidebarLinks.forEach(link => {
        link.removeEventListener('click', toggleSidebar);
      });
    };
  }, []);

  return (
    <>
      <div className="topbar"></div>
      <div className="navi">
        <div className="navi-menu">
          <a href="/">
            <img className="navi-logo" src="/assets/images/logo.png" alt="密蜜" />
            {/* <span className="navi-title">密蜜 - mitsu -</span>s */}
          </a>
          <ul className="navi-list">
            <li className="navi-item"><a href={routes.top}>Home</a></li>
            <li className="navi-item"><a href={routes.about}>About</a></li>
            <li className="navi-item"><a href={routes.contact}>Contact</a></li>
          </ul>
        </div>
        <div className="navi-subindex" onClick={toggleSidebar}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
        </svg>
        </div>
      </div>

      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button
          className="close-btn"
          onClick={toggleSidebar}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
        </button>
        <div className="sidebar-content">
          <div style={{textAlign: 'center'}}>
            <img src="/assets/images/shop_logo1.png" class="mv-logo" alt="" />
          </div>
          <ul className="sidebar-list">
              <li><a href="/">トップページ</a></li>
              <li><a href="/about">店舗情報</a></li>
              <li><a href="/about#mitsu_system">システム</a></li>
              <li><a href="/about#mitsu_access">アクセス</a></li>
              <li><a href="/staffs">スタッフ</a></li>
              <li><a href="/schedule">スケジュール</a></li>
              <li><a href="/gallery">ギャラリー</a></li>
              <li><a href="/recruit">リクルート</a></li>
              <li><a href="/contact">コンタクト</a></li>
              <hr />
              <li><a href="https://shigonawabingo.com/" target="_blank" rel="noreferrer">紫護縄 びんご.com</a></li>
              <li><a href="https://salon.shigonawabingo.com/" target="_blank" rel="noreferrer">紫護縄 SALON</a></li>
              <li><a href="https://backdoor.shigonawabingo.com/" target="_blank" rel="noreferrer">BACK DOOR</a></li>
              <hr />
              <li style={{fontSize: '16px'}}><a href="/terms_of_use">ご利用規約</a></li>
              <li style={{fontSize: '16px'}}><a href="/privacy_policy">プライバシーポリシー</a></li>
          </ul>
        </div>

        <div className="ml20 sns-icons">
          <div className="twitter sns-icon">
            <a href="https://x.com/mitsunawa" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32zzw" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
              </svg>
            </a>
          </div>

          {/* <div className="instagram sns-icon">
            <a href="https://x.com/mitsunawa" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
              </svg>
            </a>
          </div> */} 
        </div>

        <img src="/assets/common/twitter-x.svg" alt="twitter" />
        {/* <p>ここにサイドバーの内容が入ります。</p>
        <p>適当な文字列を追加してください。</p> */}
        
      </div>
    </>
  );
};

export default Nav;
