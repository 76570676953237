import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/main.scss';

import Nav from '../components/Nav';
import Footer from '../components/Footer';

const Contact = () => {
	return (
		<>
      <Nav />
        <div className="container contact">
          <div className="row pt100 pb100">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <h1 >Contact</h1>
              <div className="contact-area mt30 mb30">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">お名前</label>
                  <input type="text" className="form-control" id="name" placeholder="お名前を入力してください" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">メールアドレス</label>
                  <input type="email" className="form-control" id="email" placeholder="name@example.com" required />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">メッセージ</label>
                  <textarea className="form-control" id="message" rows="4" placeholder="お問い合わせ内容を入力してください" required></textarea>
                </div>
                <button type="submit" className="btn btn-primary shigonawa-btn">お問い合わせを送信</button>
              </div>

            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="ad-area">
                <img src="/assets/common/pubphoto001.jpg" alt="schedule" />
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </>
  );
};

export default Contact;